@import './livekit.css';
@import './widgets.css';
@import './react-calendar-heatmap.css';

@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
    font-family: 'custom-font';
    src: url(../assets/fonts/CustomFont-Book.woff2) format('woff2'),
    url(../assets/fonts/CustomFont-Book.woff) format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'custom-font';
    src: url(../assets/fonts/CustomFont-Medium.woff2) format('woff2'),
    url(../assets/fonts/CustomFont-Medium.woff) format('woff');
    font-weight: 500;
    font-style: normal;
}

/*.bg-animation:after {*/
/*    content: "";*/
/*    --angle: 0deg;*/
/*    border-radius: 0.6rem;*/
/*    @join absolute inset-0 z-0 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500*/
/*}*/

/*@property --angle {*/
/*    syntax: "<angle>";*/
/*    initial-value: 0deg;*/
/*    inherits: false*/
/*}*/

/*@keyframes rotate-animation {*/
/*    to {*/
/*        --angle: 360deg*/
/*    }*/
/*}*/

/*@supports (background: paint(houdini)) {*/
/*    .bg-animation:after {*/
/*        animation: rotate-animation 10s linear infinite;*/
/*        background: linear-gradient(var(--angle),#ec4899, #407cff,#ec4899,#6366f1);*/
/*    }*/
/*}*/




/*@layer base {*/
/*    :root {*/

/*        --font-heading: "Manrope Variable", "Manrope", "Inter", system-ui,*/
/*        -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue",*/
/*        Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",*/
/*        "Segoe UI Symbol", "Noto Color Emoji";*/

/*        --font-sans: "Inter", system-ui, -apple-system, BlinkMacSystemFont,*/
/*        "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,*/
/*        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",*/
/*        "Noto Color Emoji";*/

/*        --background: 0 0% 100%;*/
/*        --foreground: 240 10% 3.9%;*/
/*        --card: 0 0% 100%;*/
/*        --card-foreground: 240 10% 3.9%;*/
/*        --popover: 0 0% 100%;*/
/*        --popover-foreground: 240 10% 3.9%;*/
/*        --primary: 240 5.9% 10%;*/
/*        --primary-foreground: 0 0% 98%;*/
/*        --secondary: 240 4.8% 95.9%;*/
/*        --secondary-foreground: 240 5.9% 10%;*/
/*        --muted: 240 4.8% 95.9%;*/
/*        --muted-foreground: 240 3.8% 46.1%;*/
/*        --accent: 240 4.8% 95.9%;*/
/*        --accent-foreground: 240 5.9% 10%;*/
/*        --destructive: 0 84.2% 60.2%;*/
/*        --destructive-foreground: 0 0% 98%;*/
/*        --border: 240 5.9% 90%;*/
/*        --input: 240 5.9% 90%;*/
/*        --ring: 240 5.9% 10%;*/
/*        --radius: 1rem;*/
/*    }*/

/*    .dark {*/
/*        --background: 240 10% 3.9%;*/
/*        --foreground: 0 0% 98%;*/
/*        --card: 240 10% 3.9%;*/
/*        --card-foreground: 0 0% 98%;*/
/*        --popover: 240 10% 3.9%;*/
/*        --popover-foreground: 0 0% 98%;*/
/*        --primary: 0 0% 98%;*/
/*        --primary-foreground: 240 5.9% 10%;*/
/*        --secondary: 240 3.7% 15.9%;*/
/*        --secondary-foreground: 0 0% 98%;*/
/*        --muted: 240 3.7% 15.9%;*/
/*        --muted-foreground: 240 5% 64.9%;*/
/*        --accent: 240 3.7% 15.9%;*/
/*        --accent-foreground: 0 0% 98%;*/
/*        --destructive: 0 62.8% 30.6%;*/
/*        --destructive-foreground: 0 0% 98%;*/
/*        --border: 240 3.7% 15.9%;*/
/*        --input: 240 3.7% 15.9%;*/
/*        --ring: 240 4.9% 83.9%;*/
/*    }*/
/*}*/

/*'bright-gray': {*/
/* '50': '#f6f7f9',*/
/* '100': '#ebeef3',*/
/* '200': '#d3d9e4',*/
/* '300': '#acb9cd',*/
/* '400': '#8093b0',*/
/* '500': '#607697',*/
/* '600': '#4c5e7d',*/
/* '700': '#3e4d66',*/
/* '800': '#364256',*/
/* '900': '#333c4d',*/
/* '950': '#202531',*/
/* },*/

@layer base {
    :root {
        --font-heading: "Manrope Variable", "Manrope", "Inter", system-ui,
            -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue",
            Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
            "Segoe UI Symbol", "Noto Color Emoji";

        --font-sans: "Inter", system-ui, -apple-system, BlinkMacSystemFont,
            "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
            "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
            "Noto Color Emoji";

        /*!* RED *!*/
        /*--brand-foreground: 350, 89%, 91%;*/
        /*--brand-50: 350, 89%, 90%;*/
        /*--brand-100: 350, 89%, 80%;*/
        /*--brand-200: 350, 89%, 70%;*/
        /*--brand-300: 350, 89%, 65%;*/
        /*--brand-400: 350, 89%, 62%;*/
        /*--brand-500: 350, 89%, 60%;*/
        /*--brand-600: 350, 89%, 50%;*/
        /*--brand-700: 350, 89%, 40%;*/
        /*--brand-800: 350, 89%, 30%;*/
        /*--brand-900: 350, 89%, 25%;*/




        /*!* ROSE *!*/
        /*--brand-foreground: 356, 100%, 97%;*/
        /*--brand-50: 356, 100%, 95%;*/
        /*--brand-100: 353, 96%, 90%;*/
        /*--brand-200: 353, 96%, 82%;*/
        /*--brand-300: 351, 95%, 71%;*/
        /*--brand-400: 350, 89%, 60%;*/
        /*--brand-500: 347, 77%, 50%;*/
        /*--brand-600: 345, 83%, 41%;*/
        /*--brand-700: 343, 80%, 35%;*/
        /*--brand-800: 342, 75%, 30%;*/
        /*--brand-900: 343, 88%, 16%;*/



        /*--brand-foreground: 350, 89%, 91%;*/

        /* PINK */
        /*--brand-50: 330, 100%, 79%;  !* #FF8ABE *!*/
        /*--brand-100: 330, 100%, 76%; !* #FF7AB6 *!*/
        /*--brand-200: 330, 100%, 70%; !* #FF61A8 *!*/
        /*--brand-300: 330, 100%, 64%; !* #FF4297 *!*/
        /*--brand-400: 330, 100%, 59%; !* #FF2989 *!*/
        /*--brand-500: 330, 100%, 53%; !* #FF0B79 *!*/
        /*--brand-600: 330, 100%, 48%; !* #EB006A *!*/
        /*--brand-700: 330, 100%, 42%; !* #C7005A *!*/
        /*--brand-800: 330, 100%, 36%; !* #A8004C *!*/
        /*--brand-900: 330, 100%, 31%; !* #85003C *!*/
        /*--brand-950: 330, 100%, 28%; !* #750035 *!*/

        /* TEAL */
        /*--brand-25: 165, 80%, 98%;*/
        /*--brand-50: 162, 76%, 97%;*/
        /*--brand-100: 165, 85%, 89%;*/
        /*--brand-200: 166, 84%, 78%;*/
        /*--brand-300: 169, 76%, 64%;*/
        /*--brand-400: 170, 65%, 50%;*/
        /*--brand-500: 171, 79%, 40%;*/
        /*--brand-600: 173, 83%, 32%;*/
        /*--brand-700: 173, 76%, 26%;*/
        /*--brand-800: 174, 68%, 22%;*/
        /*--brand-900: 174, 61%, 19%;*/
        /*--brand-950: 174, 61%, 10%;*/





        /* PURPLE */
        /*--brand-25: 271, 100%, 99%;*/
        /*--brand-50: 270, 100%, 99%;*/
        /*--brand-100: 270, 100%, 98%;*/
        /*--brand-200: 270, 100%, 94%;*/
        /*--brand-300: 268, 90%, 90%;*/
        /*--brand-400: 267, 81%, 81%;*/
        /*--brand-500: 267, 76%, 70%;*/
        /*--brand-600: 262, 64%, 61%;*/
        /*--brand-700: 261, 53%, 50%;*/
        /*--brand-800: 259, 37%, 38%;*/
        /*--brand-900: 258, 32%, 28%;*/
        /*--brand-950: 258, 30%, 20%;*/

        /* DARK BLUE */
        /*--brand-25: 222, 100%, 98%;*/
        /*--brand-50: 221, 100%, 97%;*/
        /*--brand-100: 220, 100%, 91%;*/
        /*--brand-200: 220, 100%, 85%;*/
        /*--brand-300: 220, 100%, 76%;*/
        /*--brand-400: 220, 100%, 66%;*/
        /*--brand-500: 220, 100%, 58%;*/
        /*--brand-600: 220, 87%, 51%;*/
        /*--brand-700: 220, 100%, 46%;*/
        /*--brand-800: 220, 100%, 38%;*/
        /*--brand-900: 220, 100%, 31%;*/
        /*--brand-950: 220, 100%, 20%;*/

        /* AMBER */
        /*--brand-50: 39, 100%, 95%;  !* #FFFBEB *!*/
        /*--brand-100: 39, 100%, 90%; !* #FEF3C7 *!*/
        /*--brand-200: 39, 100%, 80%; !* #FDE68A *!*/
        /*--brand-300: 39, 100%, 70%; !* #FCD34D *!*/
        /*--brand-400: 39, 100%, 60%; !* #FBBF24 *!*/
        /*--brand-500: 39, 100%, 50%; !* #F59E0B *!*/
        /*--brand-600: 39, 100%, 45%; !* #D97706 *!*/
        /*--brand-700: 39, 100%, 40%; !* #B45309 *!*/
        /*--brand-800: 39, 100%, 35%; !* #92400E *!*/
        /*--brand-900: 39, 100%, 30%; !* #78350F *!*/

        /*--brand-50: 207, 90%, 95%;  !* Very Light Blue: Ideal for backgrounds and light themes *!*/
        /*--brand-100: 207, 90%, 90%; !* Light Blue: Great for lighter elements, borders, and shadows *!*/
        /*--brand-200: 207, 90%, 80%; !* Soft Blue: Useful for hover states and secondary buttons *!*/
        /*--brand-300: 207, 90%, 70%; !* Medium Blue: Perfect for icons and active elements *!*/
        /*--brand-400: 207, 90%, 60%; !* Standard Blue: Ideal for primary buttons and interactive elements *!*/
        /*--brand-500: 207, 90%, 50%; !* Deep Blue: Strong and impactful, great for important UI elements *!*/
        /*--brand-600: 207, 90%, 40%; !* Darker Blue: Suitable for text and important information *!*/
        /*--brand-700: 207, 90%, 30%; !* Very Dark Blue: Excellent for text and contrast elements *!*/
        /*--brand-800: 207, 90%, 20%; !* Near-Black Blue: Best for text and accents in dark mode *!*/
        /*--brand-900: 207, 90%, 10%; !* Almost Black: Ideal for text and UI elements in dark themes *!*/


        /* EMERALD */
        /*--brand-50: 125, 65%, 93%;  !* #ECFDF5 *!*/
        /*--brand-100: 125, 55%, 84%; !* #D1FAE5 *!*/
        /*--brand-200: 125, 49%, 70%; !* #A7F3D0 *!*/
        /*--brand-300: 125, 44%, 56%; !* #6EE7B7 *!*/
        /*--brand-400: 125, 54%, 42%; !* #34D399 *!*/
        /*--brand-500: 125, 64%, 31%; !* #10B981 *!*/
        /*--brand-600: 125, 73%, 28%; !* #059669 *!*/
        /*--brand-700: 125, 85%, 25%; !* #047857 *!*/
        /*--brand-800: 125, 86%, 20%; !* #065F46 *!*/
        /*--brand-900: 125, 90%, 18%; !* #064E3B *!*/

        /* GREEN */
        /*--brand-50: 138, 76%, 97%;  !* #F0FDF4 *!*/
        /*--brand-100: 141, 84%, 93%; !* #DCFCE7 *!*/
        /*--brand-200: 141, 79%, 85%; !* #BBF7D0 *!*/
        /*--brand-300: 142, 77%, 73%; !* #86EFAC *!*/
        /*--brand-400: 142, 69%, 58%; !* #4ADE80 *!*/
        /*--brand-500: 142, 71%, 45%; !* #22C55E *!*/
        /*--brand-600: 142, 76%, 36%; !* #16A34A *!*/
        /*--brand-700: 142, 72%, 29%; !* #15803D *!*/
        /*--brand-800: 143, 64%, 24%; !* #166534 *!*/
        /*--brand-900: 144, 61%, 20%; !* #14532D *!*/




        /*  GREEN 2 */
        /*--brand-50: 120, 67%, 96%;  !* #F0FDF4 *!*/
        /*--brand-100: 123, 60%, 92%; !* #DCFCE7 *!*/
        /*--brand-200: 123, 55%, 84%; !* #BBF7D0 *!*/
        /*--brand-300: 123, 53%, 76%; !* #86EFAC *!*/
        /*--brand-400: 123, 52%, 68%; !* #4ADE80 *!*/
        /*--brand-500: 122, 55%, 60%; !* #22C55E *!*/
        /*--brand-600: 122, 60%, 50%; !* #16A34A *!*/
        /*--brand-700: 122, 63%, 42%; !* #15803D *!*/
        /*--brand-800: 122, 65%, 34%; !* #166534 *!*/
        /*--brand-900: 125, 65%, 26%; !* #14532D *!*/



        /* ORANGE */
        /*--secondary-50: 24, 100%, 95%;  !* Very Light Orange: Ideal for background highlights *!*/
        /*--secondary-100: 24, 100%, 90%; !* Light Orange: Great for lighter elements and soft accents *!*/
        /*--secondary-200: 24, 100%, 80%; !* Soft Orange: Useful for hover states and secondary buttons *!*/
        /*--secondary-300: 24, 100%, 70%; !* Medium Orange: Perfect for icons and active elements *!*/
        /*--secondary-400: 24, 100%, 60%; !* Standard Orange: Ideal for primary buttons and interactive elements *!*/
        /*--secondary-500: 24, 100%, 50%; !* Deep Orange: Strong and impactful, great for important UI elements *!*/
        /*--secondary-600: 24, 100%, 40%; !* Darker Orange: Suitable for text and important information *!*/
        /*--secondary-700: 24, 100%, 30%; !* Very Dark Orange: Excellent for text and contrast elements *!*/
        /*--secondary-800: 24, 100%, 20%; !* Near-Black Orange: Best for text and accents in dark mode *!*/
        /*--secondary-900: 24, 100%, 10%; !* Almost Black: Ideal for text and UI elements in dark themes *!*/



        /* BLUE */
        /*--brand-25: 210, 100%, 98%;*/
        /*--brand-50: 206, 100%, 97%;*/
        /*--brand-100: 209, 100%, 91%;*/
        /*--brand-200: 206, 100%, 85%;*/
        /*--brand-300: 206, 100%, 76%;*/
        /*--brand-400: 207, 98%, 66%;*/
        /*--brand-500: 211, 95%, 58%;*/
        /*--brand-600: 215, 87%, 51%;*/
        /*--brand-700: 218, 80%, 46%;*/
        /*--brand-800: 220, 75%, 38%;*/
        /*--brand-900: 218, 68%, 31%;*/
        /*--brand-950: 218, 69%, 20%;*/

        /*--secondary-25: 240 3.7% 15.9%;*/
        /*--secondary-50: 240 3.7% 15.9%;*/
        /*--secondary-100: 240 3.7% 15.9%;*/
        /*--secondary-200: 240 3.7% 15.9%;*/
        /*--secondary-300: 240 3.7% 15.9%;*/
        /*--secondary-400: 240 3.7% 15.9%;*/
        /*--secondary-500: 240 3.7% 15.9%;*/
        /*--secondary-600: 240 3.7% 15.9%;*/
        /*--secondary-700: 240 3.7% 15.9%;*/
        /*--secondary-800: 240 3.7% 15.9%;*/
        /*--secondary-900: 240 3.7% 15.9%;*/
        /*--secondary-950: 240 3.7% 15.9%;*/

        /*--brand-25: 0 0% 98%;*/
        /*--brand-50: 0 0% 98%;*/
        /*--brand-100: 0 0% 98%;*/
        /*--brand-200: 0 0% 98%;*/
        /*--brand-300: 0 0% 98%;*/
        /*--brand-400: 0 0% 98%;*/
        /*--brand-500: 0 0% 98%;*/
        /*--brand-600: 0 0% 98%;*/
        /*--brand-700: 0 0% 98%;*/
        /*--brand-800: 0 0% 98%;*/
        /*--brand-900: 0 0% 98%;*/
        /*--brand-950: 0 0% 98%;*/



    /*  INDIGO  */
        --brand-25: 222, 100%, 98%;
        --brand-50: 219, 100%, 97%;
        --brand-100: 221, 100%, 94%;
        --brand-200: 223, 96%, 89%;
        --brand-300: 224, 96%, 82%;
        --brand-400: 228, 91%, 74%;
        --brand-500: 233, 86%, 67%;
        --brand-600: 237, 77%, 59%;
        --brand-700: 239, 60%, 51%;
        --brand-800: 238, 57%, 41%;
        --brand-900: 236, 49%, 34%;
        --brand-950: 236, 49%, 24%;

        /* TEAL */
        --secondary-25: 165, 80%, 98%;
        --secondary-50: 162, 76%, 97%;
        --secondary-100: 165, 85%, 89%;
        --secondary-200: 166, 84%, 78%;
        --secondary-300: 169, 76%, 64%;
        --secondary-400: 170, 65%, 50%;
        --secondary-500: 171, 79%, 40%;
        --secondary-600: 173, 83%, 32%;
        --secondary-700: 173, 76%, 26%;
        --secondary-800: 174, 68%, 22%;
        --secondary-900: 174, 61%, 19%;
        --secondary-950: 174, 61%, 10%;


    /*    --brand-25: 45, 100%, 98%;  !* Very light yellow *!*/
    /*    --brand-50: 43, 100%, 97%;  !* Light yellow *!*/
    /*    --brand-100: 44, 100%, 94%; !* Soft yellow *!*/
    /*    --brand-200: 46, 96%, 89%;  !* Pale amber *!*/
    /*    --brand-300: 47, 96%, 82%;  !* Light amber *!*/
    /*    --brand-400: 48, 91%, 74%;  !* Medium amber *!*/
    /*    --brand-500: 50, 86%, 67%;  !* Amber *!*/
    /*    --brand-600: 52, 77%, 59%;  !* Dark amber *!*/
    /*    --brand-700: 53, 60%, 51%;  !* Deeper amber *!*/
    /*    --brand-800: 54, 57%, 41%;  !* Darker amber *!*/
    /*    --brand-900: 55, 49%, 34%;  !* Deep amber *!*/
    /*    --brand-950: 56, 49%, 24%;  !* Almost brownish amber *!*/

    /*    --brand-50: 158, 76%, 80%;     !* #8CF5D2 *!*/
    /*    --brand-100: 158, 76%, 76%;    !* #79F3CB *!*/
    /*    --brand-200: 158, 76%, 66%;    !* #53F0BC *!*/
    /*    --brand-300: 158, 76%, 56%;    !* #2EEDAE *!*/
    /*    --brand-400: 158, 76%, 47%;    !* #13DF9B *!*/
    /*    --brand-500: 158, 89%, 38%;    !* #10B981 *!*/
    /*    --brand-600: 158, 76%, 29%;    !* #0C855D *!*/
    /*    --brand-700: 158, 76%, 20%;    !* #075239 *!*/
    /*    --brand-800: 158, 76%, 11%;    !* #031E15 *!*/
    /*    --brand-900: 0, 0%, 0%;        !* #000000 *!*/
    /*    --brand-950: 0, 0%, 0%;        !* #000000 *!*/



        /* VIOLET */
        /*--brand-25: 252, 100%, 99%;*/
        /*--brand-50: 250, 100%, 98%;*/
        /*--brand-100: 249, 91%, 95%;*/
        /*--brand-200: 251, 95%, 92%;*/
        /*--brand-300: 252, 95%, 85%;*/
        /*--brand-400: 254, 93%, 76%;*/
        /*--brand-500: 257, 91%, 66%;*/
        /*--brand-600: 261, 84%, 58%;*/
        /*--brand-700: 262, 71%, 50%;*/
        /*--brand-800: 262, 70%, 42%;*/
        /*--brand-900: 262, 69%, 35%;*/
        /*--brand-950: 262, 68%, 22%;*/








        --primary-foreground: 144, 68%, 85%;
        --primary-50: 144, 100%, 97%;
        --primary-100: 144, 82%, 92%;
        --primary-200: 144, 64%, 81%;
        --primary-300: 144, 51%, 66%;
        --primary-400: 144, 46%, 49%;
        --primary-500: 144, 61%, 39%;
        --primary-600: 144, 75%, 32%;
        --primary-700: 144, 79%, 27%;
        --primary-800: 144, 72%, 24%;
        --primary-900: 144, 68%, 20%;

        /*--scale-forground: 0, 0%, 100%;*/
        /*--scale-50: 0, 0%, 98%;*/
        /*--scale-100: 0, 0%, 90%;*/
        /*--scale-200: 0, 0%, 80%;*/
        /*--scale-300: 0, 0%, 65%;*/
        /*--scale-400: 0, 0%, 54%;*/
        /*--scale-500: 0, 0%, 40%;*/
        /*--scale-600: 0, 0%, 25%;*/
        /*--scale-700: 0, 0%, 20%;*/
        /*--scale-800: 0, 0%, 15%;*/
        /*--scale-900: 0, 0%, 7%;*/
        --scale-foreground: 240, 10%, 99%;


        --scale-25: 240, 20%, 99%;
        --scale-50: 210, 40%, 98%;
        --scale-100: 210, 31%, 95%;
        --scale-200: 215, 27%, 91%;
        --scale-300: 213, 22%, 84%;
        --scale-400: 215, 13%, 65%;
        --scale-500: 215, 12%, 47%;
        --scale-600: 217, 15%, 35%;
        --scale-700: 216, 21%, 27%;
        --scale-800: 218, 28%, 17%;
        --scale-900: 219, 36%, 11%;
        --scale-950: 220, 37%, 8%;

        /* modern gray (original) */
        /*--scale-25: 240, 10%, 99%;*/
        /*--scale-50: 210, 14%, 98%;*/
        /*--scale-100: 210, 16%, 95%;*/
        /*--scale-200: 215, 16%, 91%;*/
        /*--scale-300: 220, 16%, 82%;*/
        /*--scale-400: 220, 7%, 63%;*/
        /*--scale-500: 210, 8%, 46%;*/
        /*--scale-600: 220, 14%, 33%;*/
        /*--scale-700: 220, 18%, 27%;*/
        /*--scale-800: 220, 23%, 18%;*/
        /*--scale-900: 220, 27%, 11%;*/
        /*--scale-950: 210, 45%, 9%;*/

        /*--scale-25: 240, 10%, 99%;*/
        /*--black: 210, 45%, 9%;*/

        /*--scale-25: 0, 0%, 100%;*/
        /*--black: 0, 0%, 0%;*/

        --background: 0 0% 100%;
        --foreground: 222.2 47.4% 11.2%;

        --muted: 210 40% 96.1%;
        --muted-foreground: 215.4 16.3% 46.9%;

        --popover: 0 0% 100%;
        --popover-foreground: 222.2 47.4% 11.2%;

        --card: 0 0% 100%;
        --card-foreground: 222.2 47.4% 11.2%;

        --border: 214.3 31.8% 91.4%;
        --input: 214.3 31.8% 91.4%;

        /*--primary: 222.2 47.4% 11.2%;*/
        /*--primary-foreground: 210 40% 98%;*/

        --secondary: 210 40% 96.1%;
        --secondary-foreground: 222.2 47.4% 11.2%;

        --accent: 210 40% 96.1%;
        --accent-foreground: 222.2 47.4% 11.2%;

        --destructive: 0 100% 50%;
        --destructive-foreground: 210 40% 98%;

        --ring: 215 20.2% 65.1%;

        --radius: 0.5rem;
    }

    .dark {
        --background: 224 71% 4%;
        --foreground: 213 31% 91%;

        --muted: 223 47% 11%;
        --muted-foreground: 215.4 16.3% 56.9%;

        --popover: 224 71% 4%;
        --popover-foreground: 215 20.2% 65.1%;

        --card: 224 71% 4%;
        --card-foreground: 213 31% 91%;

        --border: 216 34% 17%;
        --input: 216 34% 17%;

        --primary: 210 40% 98%;
        --primary-foreground: 222.2 47.4% 1.2%;

        --secondary: 222.2 47.4% 11.2%;
        --secondary-foreground: 210 40% 98%;

        --accent: 216 34% 17%;
        --accent-foreground: 210 40% 98%;

        --destructive: 0 63% 31%;
        --destructive-foreground: 210 40% 98%;

        --ring: 216 34% 17%;

        --radius: 0.5rem;
    }
    h1, h2, h3, h4, h5, h6 {
        font-family: var(--font-heading);
        font-weight: 500;
        color: var(--foreground);
    }
}

.react-markdown > * {
    all: revert;
    white-space: pre-wrap;
}
