@tailwind components;
@layer components {

    .react-calendar-heatmap .color-empty {
        @apply fill-scale-100 !important;
    }

    .react-calendar-heatmap .color-scale-1 {
        @apply fill-brand-300;
    }

    .react-calendar-heatmap .color-scale-2 {
        @apply fill-brand-400;
    }

    .react-calendar-heatmap .color-scale-3 {
        @apply fill-brand-500;
    }

    .react-calendar-heatmap .color-scale-4 {
        @apply fill-brand-800;
    }

    .react-calendar-heatmap text.react-calendar-heatmap-month-label {
        font-size: 7px !important;
    }

    .react-calendar-heatmap rect:hover {
        @apply stroke-brand-300 !important;
        /*stroke:  !important;*/
        /*stroke-width: 1px !important;*/
    }


}


