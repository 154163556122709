/*
    hide username container
    since we pre-populate this
*/
.lk-username-container > .lk-form-control {
    display: none;
}

.lk-username-container > .lk-button.lk-join-button {
    /*background-color: hsl(var(--brand));*/
    /*color: hsl(var(--scale-25));*/
    @apply rounded-md text-scale-100 bg-brand hover:bg-brand/70 font-medium font-mono uppercase tracking-widest
}

button.lk-button.lk-chat-toggle {
    @apply hidden
}

.lk-button {
    @apply text-scale-950 dark:text-scale-25
}

.lk-prejoin .lk-device-menu {
    @apply bg-scale-100 dark:bg-scale-950
}

.lk-prejoin .lk-device-menu .lk-button {
    @apply text-scale-600 dark:text-scale-300
}

.lk-prejoin .lk-device-menu .lk-button [data-lk-active="true"] {
    @apply text-scale-950 dark:text-scale-25
}

